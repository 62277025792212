import { Grid, Button } from "@material-ui/core";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

const Appbar = () => {
  return (
    <div>
      <Grid
        container
        style={{
          padding: "5px",
          backgroundColor: "#35A29F",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h2
          style={{
            // paddingRight: "800px",
            color: "white",
            fontSize: "bold",
            fontWeight: "2rem",
            textDecoration: "none",
            
          }}
        >
         SAP
        </h2>
      </Grid>
    </div>
  );
};

export default Appbar;
