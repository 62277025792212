import React, { useEffect, useRef } from "react";
import Box from "@mui/material/Box";

const PSPDFKitComponent = () => {
  const pspdfkitContainerRef = useRef(null);

  useEffect(() => {
    // Dynamically load PSPDFKit
    const script = document.createElement("script");
    script.src = "assets/pspdfkit.js";
    script.async = true;
    document.body.appendChild(script);

    const container = pspdfkitContainerRef.current;

    const initializePSPDFKit = () => {
      window.PSPDFKit.load({
        container,
        document: "document.docx", // Add the path to your document here.
      })
        .then((instance) => {
          // Logic after loading the document
          return instance.exportPDF({
            outputFormat: {
              conformance: window.PSPDFKit.Conformance.PDFA_4F,
            },
          });
        })
        .then((buffer) => {
          downloadPdf(buffer);
        })
        .catch((error) => {
          console.error(error.message);
        });
    };

    if (script.readyState) { // For IE
      script.onreadystatechange = () => {
        if (script.readyState === "loaded" || script.readyState === "complete") {
          script.onreadystatechange = null;
          initializePSPDFKit();
        }
      };
    } else { // Other browsers
      script.onload = initializePSPDFKit;
    }

    // Cleanup function
    return () => {
      if (window.PSPDFKit) {
        window.PSPDFKit.unload(container);
      }
      document.body.removeChild(script);
    };
  }, []);

  const downloadPdf = (buffer) => {
    const blob = new Blob([buffer], { type: "application/pdf" });
    const objectUrl = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = objectUrl;
    a.download = "output.pdf";
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(objectUrl);
    document.body.removeChild(a);
  };

  return (
    <Box
      ref={pspdfkitContainerRef}
      sx={{
        width: "100%",
        height: "100vh",
      }}
    />
  );
};

export default PSPDFKitComponent;
