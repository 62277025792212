import React from "react";
import Footer from "./footer";
import { Outlet } from "react-router-dom";
import Appbar from "./appbar";


function RootLayout() {
    return(
        <div>
            <Appbar/>
            <Outlet/>
            <Footer/>
        </div>
    )
}

export default RootLayout