import { useState } from 'react';

const useCheckbox = (initialValue = false) => {
  const [isChecked, setIsChecked] = useState(initialValue);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  return [isChecked, handleCheckboxChange, setIsChecked];
};

export default useCheckbox;