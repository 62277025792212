import * as React from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { Grid } from "@mui/material";
import axios, { isCancel } from "axios";
import useCheckbox from "./CheckedContext";
import { useNavigate } from "react-router-dom";
import CancelIcon from "@mui/icons-material/Cancel";
import DeleteIcon from "@mui/icons-material/Delete";
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export default function FullWidthTabs4() {
  const [rows, setRows] = React.useState([]);
  // console.log(rows.date);
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  React.useEffect(() => {
    const getData = async () => {
      try {
        const response = await axios.post(
          // "https://thulasisolutions.in:3004/getData"
          "https://thulasisolutions.in:3004/getData"
        );
        const fetchedData = response.data;
        // console.log(fetchedData);
        const mappedRows = fetchedData.map((item, index) => {
          return {
            post_id: item._id,
            id: index + 1,
            name: item.name,
            date: item.date,
            course: item.course,
            title: item.title,
            description: item.description,
            youtubeurl: item.youtubeurl,
            otherurl: item.otherurl,
            status: item.radioButtonOption,
            errortitle: item.errortitle,
            errordescription: item.errordescription,
            expectedsolution: item.expectedsolution,
            erroryoutubeurl: item.erroryoutubeurl,
            errorotherurl: item.errorotherurl,
            docfile: item.files.filter((file) => file.includes("docfile")),
            normalVideo: item.files.filter((file) =>
              file.includes("normalvideo")
            ),
            // imagefile: item.files.filter((file) => file.includes("imagefile")),

            errdoc: item.files.filter((file) => file.includes("errordoc")),
            errorVideo: item.files.filter((file) =>
              file.includes("errorvideo")
            ),
            // errorimage: item.files.filter((file) =>
            //   file.includes("errorimage")
            // ),
          };
        });
        setRows(mappedRows);
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle error appropriately
      }
    };
    getData();
  }, []);

  //coloums fields

  const handleDeleteClick = async (id) => {
    // Display a confirmation dialog
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this row?"
    );
    // Proceed only if the user confirmed
    if (isConfirmed) {
      try {
        // Append the ID to the URL as a parameter
        const response = await axios.post(
          "https://thulasisolutions.in:3004/deletePost",
          { id: id },
          { headers: { "Content-Type": "application/json" } } // Ensure proper content type header
        );

        if (response.status === 200) {
          // Assuming the deletion was successful, remove the item from the UI
          setRows((currentRows) =>
            currentRows.filter((row) => row.post_id !== id)
          );
          alert("Row deleted successfully");
        } else {
          // Handle any unsuccessful response accordingly
          alert("Failed to delete the row");
        }
      } catch (error) {
        console.error("Failed to delete the row:", error);
        alert("Failed to delete the row");
      }
    } else {
      // If the user clicks 'Cancel', do nothing
      console.log("Row deletion cancelled.");
    }
  };

  const columns1 = [
    {
      field: "id",
      headerName: "SL NO",
      width: 75,
    },
    {
      field: "date",
      headerName: "DATE",
      width: 150,
    },
    {
      field: "name",
      headerName: "NAME",
      width: 180,
    },
    {
      field: "course",
      headerName: "COURSE",
      width: 150,
    },
    {
      field: "title",
      headerName: "TITLE",
      width: 250,
    },

    {
      field: "description",
      headerName: "DESCRIPTION",
      width: 280,
    },
    {
      field: "youtubeurl",
      headerName: "URL",
      width: 250,
    },
    {
      field: "status",
      headerName: "STATUS",
      width: 130,
    },

    {
      field: "actions",
      headerName: "ACTIONS",
      width: 250,
      renderCell: (params) => (
        <Box display="flex" alignItems="center" style={{ gap: "5px" }}>
          {/* <Button
            variant="contained"
            style={{ backgroundColor: "#0063cc" }}
            onClick={() => handleUpdateClick(params.row)}
          >
            Update
          </Button>{" "} */}
          {/* Add left margin */}
          <ViewButton rowData={params.row} />
          <DeleteIcon
            style={{ cursor: "pointer", color: "#EF4040" }}
            onClick={() => handleDeleteClick(params.row.post_id)}
          />
        </Box>
      ),
    },
  ];
  // ... other states and effects
  const navigate = useNavigate();
  const handleUpdateClick = (rowData) => {
    navigate("/dataform", { state: { data: rowData } });
  };
  // console.log(data);

  // const [isChecked, setIsChecked] = Dataform()

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  // const UpdateButton = () => {
  //   return (
  //     <Button variant="contained" style={{ backgroundColor: "#0063cc" }}>
  //       Update
  //     </Button>
  //   );
  // };

  const ViewButton = ({ rowData }) => {
    let navigate = useNavigate();

    const handleViewClick = () => {
      navigate("/successview", { state: { data: rowData } }); // Pass rowData as state
    };

    return (
      <Button variant="contained" onClick={handleViewClick}>
        View
      </Button>
    );
  };

  return (
    <Box
      style={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography
        variant="h4"
        style={{
          textAlign: "center",
          fontWeight: "bold",
          paddingBottom: "50px",
          paddingTop: "30px",
        }}
      >
        DATA TABLE
      </Typography>

      <AppBar position="static" style={{ width: "90%" }}>
        <Tabs value={value} variant="fullWidth">
          <Tab
            label="DATA TABLE"
            {...a11yProps(0)}
            style={{
              backgroundColor: "lightblue",
              fontWeight: "bolder",
              color: "black",
            }}
          />
          <Grid
            style={{
              width: "90%",
              display: "flex",
              listStyle: "none",
              justifyContent: "flex-end",
              gap: "20px",
              backgroundColor: "lightblue",
              alignItems: "center",
              paddingRight: "20px",
            }}
          >
              <li>
              <Link to="/dataform">
                <Button
                  variant="contained"
                  style={{ backgroundColor: "darkcyan" }}
                >
                  Home
                </Button>
              </Link>
            </li>
            
            <li>
            <Link to="">
                <Button
                  variant="contained"
                  style={{ backgroundColor: "darkcyan" }}
                >
                  My Posts
                </Button>
              </Link>
            </li>
            
            <li>
            <Link to="">
                <Button
                  variant="contained"
                  style={{ backgroundColor: "darkcyan" }}
                >
                  All Posts
                </Button>
              </Link>
            </li>
            <li>
              <Link to="">
                <Button
                  variant="contained"
                  style={{ backgroundColor: "darkcyan" }}
                >
                  Deleted Posts
                </Button>
              </Link>
            </li>
          
            <li>
              <Link to="/loginform">
                <Button
                  variant="contained"
                  style={{ backgroundColor: "darkcyan" }}
                >
                  Logout
                </Button>
              </Link>
            </li>
          </Grid>
          {/* <Tab label="ERROR" {...a11yProps(1)} /> */}
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
        style={{ width: "92%", backgroundColorl: "green", height: "100vh" }}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          <div style={{ height: 600, width: "100%" }}>
            <DataGrid
              rows={rows}
              columns={columns1}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 100 },
                },
              }}
              pageSizeOptions={[5, 10, 25, 50, 100]}
            />
          </div>
        </TabPanel>
      </SwipeableViews>
    </Box>
  );
}
