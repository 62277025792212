import React, { useEffect, useState, useRef } from "react";
import Webcam from "webcamjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
// import Formlist from "./Formlist";
import img from "../components/assets/ashoka-chakra.png";
import "./Login.css";
import Dataform from "./dataform";
import { useNavigate } from 'react-router-dom';

const Login = (props) => {
  const [isPasswordShown, setIsPasswordShown] = useState(false);
  const [snapshotData, setSnapshotData] = useState(null);
  const [enteredUsername, setEnteredUsername] = useState(""); // State for entered username
  const [enteredPassword, setEnteredPassword] = useState(""); // State for entered password
  const passwordRef = useRef(null);
  const myCameraInnerRef = useRef(null);
  const navigate = useNavigate();
  const users = [
    { username: "SAP", password: "1234" ,redirectTo: "/dataform"},
    { username: "Nagalakshmi", password: "0001" ,redirectTo: "/dataform"},
    { username: "Sudheer", password: "0002" ,redirectTo: "/dataform"},
    { username: "Sindhu", password: "0003" ,redirectTo: "/dataform"},
    { username: "Sattar", password: "0004" ,redirectTo: "/abapptable"},
    // Add more users as needed
  ];
  const handleLogin = () => {
    // Check if entered credentials match any user in the users array
    const foundUser = users.find(
      (user) => user.username === enteredUsername && user.password === enteredPassword
    );

    if (foundUser) {
      navigate(foundUser.redirectTo); // Navigate to the route
    }  else {
      // If not found, alert the user
      alert("Invalid username or password");
    }
  };


  useEffect(() => {
    Webcam.set({
      width: 150,
      height: 150,
      image_format: "jpeg",
      jpeg_quality: 90,
    });
    Webcam.attach(myCameraInnerRef.current);
    return () => {
      Webcam.reset();
    };
  }, []);
  // const [auth, setAuth] = useState(false);
  // if (auth) {
  //   return <Dataform />;
  // }
  const showPassword = () => {
    setIsPasswordShown(!isPasswordShown);
  };
  const take_snapshot = () => {
    Webcam.snap(function (data_uri) {
      setSnapshotData(data_uri);
      console.log("Snapshot taken:", data_uri);
    });
  };
  // const handleLogin = () => {
  //   const expectedUsername = "SAP";
  //   const expectedPassword = "1234";
  //   if (
  //     enteredUsername === expectedUsername &&
  //     enteredPassword === expectedPassword
  //   ) {
  //     setAuth(true);
  //   } else {
  //     alert("Invalid username or password");
  //   }
  // };
  return (
    // Main container for the login form, centered on the screen
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      {/* // Inner container for the form elements with a fixed width and centered
      content */}
      <div
        className="container mt-5 card"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "lightgrey",
          width: "400px",
        }}
      >
        {/* // Container for the webcam feed */}
        <div id="my_camera">
          {/* // Inner div to attach the webcam feed, with a ref for webcamjs */}
          <div className="inner" ref={myCameraInnerRef}></div>
        </div>
        {/* // Title of the form */}
        <h2 className="text-center">Sign In</h2>
        {/* // The form itself with a column layout */}
        <form
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/* // Username input group */}
          <div className="form-group">
            <label className="control-label" htmlFor="username">
              User Name
            </label>
            <input
              type="text"
              placeholder="User Name"
              title="Please enter your username"
              required
              name="username"
              id="username"
              className="form-control"
              onChange={(e) => setEnteredUsername(e.target.value)}
            />
          </div>
          {/* // Password input group with a toggle button to show/hide password */}
          <div className="form-group">
            <label className="control-label" htmlFor="password">
              Password
            </label>
            <div className="input-group" style={{display:"flex",alignItems:"center",gap:'5px'}}>
              <div>
                <input
                  type={isPasswordShown ? "text" : "password"}
                  className="form-control"
                  id="password"
                  name="password"
                  placeholder="******"
                  required
                  ref={passwordRef}
                  onChange={(e) => setEnteredPassword(e.target.value)}
                />
              </div>
              <div className="input-group-append">
                <button
                  id="show_password"
                  className="btn btn-outline-secondary"
                  type="button"
                  onClick={showPassword}
                >
                  <FontAwesomeIcon
                    icon={isPasswordShown ? faEye : faEyeSlash}
                  />
                </button>
              </div>
            </div>
          </div>
          {/* Button to take a snapshot with the webcam */}
          <div
            className="form-group mt-3"
            style={{ transform: "translate(0,24px)" }}
          >
            <input
              type="button"
              className="btn btn-block"
              value="Take Snapshot"
              onClick={() => {
                take_snapshot();
                console.log("Snapshot taken!");
              }}
              style={{
                backgroundColor: "#FF9933",
                width: "100%",
                height: "30px",
              }}
            />
          </div>
          {/* // Hidden input to store the snapshot data if taken */}
          {snapshotData && (
            <input
              type="hidden"
              name="image"
              className="image-tag"
              value={snapshotData}
            />
          )}
          {/* // Displaying an image (Ashoka Chakra in this case) */}
          <div align="center">
            <img src={img} alt="ashok chakra" style={{ marginTop: "25px" }} />
          </div>
          {/* // Login button which is enabled only if a snapshot is taken */}
          <div
            className="form-group mt-3"
            style={{ transform: "translate(0,-14px)" }}
          >
            <button
              type="button"
              name="submit"
              className="btn btn-success btn-block"
              id="loginButton"
              disabled={!snapshotData}
              style={{
                width: "100%",
                // height: "30px",
                padding:"3px",
                fontWeight:"bold",
                backgroundColor: "green",
                color: "white",
                marginTop: "10px",
                
              }}
              onClick={handleLogin}
            >
              Login
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
export default Login;
