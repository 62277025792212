import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import Pdf from "../videos/pdf0.pdf";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import video0 from "../videos/samplevideo.mp4";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Modal } from "@mui/material";
// import PDFViewer from "./Pdf_viewer";
import PDFViewer from "pdf-viewer-reactjs";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function VerticalTabs1() {
  const [open, setOpen] = useState(false); // Tracks if the modal is open
  const [selectedImage, setSelectedImage] = useState(""); // Tracks the currently selected image

  const handleOpen = (imageUrl) => {
    setOpen(true);
    setSelectedImage(imageUrl);
  };
  const handleClose = () => setOpen(false);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [showBox, setShowBox] = useState(false);

  const handleListclosed0 = (event) => {
    setShowBox(false);
  };
  const [showBox1, setShowBox1] = useState(false); // State for the first box
  // const [showBox2, setShowBox2] = useState(false); // State for the second box
  const [selectedDoc, setSelectedDoc] = useState(null);

  const handleDocumentClick = (doc) => {
    setSelectedDoc(`${URL}/${doc}`); // Set the selected document URL
    setShowBox1(true); // Show the document viewer
  };

  const location = useLocation();
  const rowData = location.state.data;
  console.log(rowData);

  const formatUrl = (url) => {
    // Check if url is defined and is a string before proceeding
    if (typeof url === "string" && url.length > 0) {
      if (!url.startsWith("https://") && !url.startsWith("https://")) {
        return `https://${url}`;
      }
      return url;
    }
    // Return the original url or a default/fallback value if it's not a valid string
    return url || "";
  };
  const [currentDocumentUrl, setCurrentDocumentUrl] = useState(""); // Initialize with an empty string

  const handleDocumentItemClick = (documentUrl) => {
    setCurrentDocumentUrl(documentUrl); // Update currentDocumentUrl when a document is clicked
  };
  function extractVideoID(url) {
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = url.match(regExp);

    if (match && match[2].length === 11) {
      return match[2];
    } else {
      return null;
    }
  }

  const URL = "https://thulasisolutions.in:3004/images/";

  return (
    <Grid
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "10px",
        backgroundColor: "#E1F0DA",
      }}
    >
      <Grid
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "10px",
          width: "100%",
          backgroundColor: "white",
        }}
      >
        <Grid
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            gap: "20px",
          }}
        >
          <Grid
            style={{
              display: "flex",
              listStyle: "none",
              gap: "20px",
            }}
          >
            <li>
              <Link to="/dataform">
                <Button
                  variant="contained"
                  style={{ backgroundColor: "darkcyan" }}
                >
                  Home
                </Button>
              </Link>
            </li>
            <li>
              <Link to="">
                <Button
                  variant="contained"
                  style={{ backgroundColor: "darkcyan" }}
                >
                  My Posts
                </Button>
              </Link>
            </li>

            <li>
              <Link to="">
                <Button
                  variant="contained"
                  style={{ backgroundColor: "darkcyan" }}
                >
                  All Posts
                </Button>
              </Link>
            </li>
            <li>
              <Link to="/abapptable">
                <Button
                  variant="contained"
                  style={{ backgroundColor: "darkcyan" }}
                >
                  VIEW CONTENT
                </Button>
              </Link>
            </li>
          </Grid>
          <Grid>
            <Link to="/loginform">
              <Button
                variant="contained"
                style={{ backgroundColor: "darkcyan" }}
              >
                Logout
              </Button>
            </Link>
          </Grid>
        </Grid>
        <Grid
          container
          lg={12}
          md={10}
          sm={12}
          xs={11}
          style={{
            display: "flex",
            backgroundColor: "#83C0C1",
            justifyContent: "space-around",
            width: "100%",
            marginBottom: "10px",
            padding: "10px",
          }}
          key={rowData.id}
        >
          <Typography style={{ color: "white" }}>
            <strong style={{ color: "black" }}> USER NAME:</strong>{" "}
            <span style={{ fontWeight: "bold" }}>{rowData.name}</span>
          </Typography>
          <Typography style={{ color: "white" }}>
            <strong style={{ color: "black" }}> TITLE:</strong>{" "}
            <span style={{ fontWeight: "bold" }}>{rowData.title}</span>
          </Typography>
          <Typography style={{ color: "white" }}>
            <strong style={{ color: "black" }}> COURSE:</strong>{" "}
            <span style={{ fontWeight: "bold" }}> {rowData.course}</span>
          </Typography>
          <Typography style={{ color: "white" }}>
            <strong style={{ color: "black" }}> STATUS:</strong>{" "}
            <span style={{ fontWeight: "bold" }}>{rowData.status}</span>
          </Typography>
          <Typography style={{ color: "white" }}>
            <strong style={{ color: "black" }}> DATE:</strong>{" "}
            <span style={{ fontWeight: "bold" }}>{rowData.date}</span>
          </Typography>
        </Grid>
        <Grid
          style={{
            display: "flex",
            justifyContent: "left",
            width: "100%",
            backgroundColor: "#E1F0DA",
            padding: "10px",
          }}
        >
          <Typography
            style={{ width: "100%", paddingLeft: "70px", fontWeight: "bold" }}
          >
            CONTENTS
          </Typography>
          <Typography
            style={{ width: "100%", fontWeight: "bold", backgroundColor: "" }}
          >
            DISCRIPTION
          </Typography>
        </Grid>
        <Grid
          style={{
            flexGrow: 1,
            width: "100%",
            display: "flex",
            // backgroundColor: "red",
          }}
        >
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            sx={{ borderRight: 1, borderColor: "divider" }}
          >
            <Tab
              style={{
                backgroundColor: "#83C0C1",
                fontWeight: "bold",
                fontSize: "1rem",
              }}
              label="DISCRIPTION"
              {...a11yProps(0)}
            />
            <Tab
              style={{ fontWeight: "bold", fontSize: "1rem" }}
              label="UPLOADED VIDEOS"
              {...a11yProps(1)}
            />
            <Tab
              style={{
                backgroundColor: "#83C0C1",
                fontWeight: "bold",
                fontSize: "1rem",
              }}
              label="UPLOADED DOCS"
              {...a11yProps(2)}
            />
            {/* <Tab
              style={{ fontWeight: "bold", fontSize: "1rem" }}
              label="UPLOADED IMAGES"
              {...a11yProps(3)}
            /> */}

            <Tab
              style={{
                fontWeight: "bold",
                fontSize: "1rem",
                // backgroundColor: "#83C0C1",
              }}
              label="ERROR DISCRIPTION"
              {...a11yProps(4)}
            />

            <Tab
              style={{
                fontWeight: "bold",
                fontSize: "1rem",
                backgroundColor: "#83C0C1",
              }}
              label=" ERROR UPLOADED VIDEOS"
              {...a11yProps(5)}
            />
            <Tab
              style={{
                fontWeight: "bold",
                // backgroundColor: "#83C0C1",
                fontSize: "1rem",
              }}
              label=" ERROR UPLOADED DOCS"
              {...a11yProps(6)}
            />
            {/* <Tab
              style={{ fontWeight: "bold", fontSize: "1rem" }}
              label="ERROR UPLOADED IMAGES"
              {...a11yProps(7)}
            /> */}
          </Tabs>
          <Grid container lg={12} md={10} sm={12} xs={11} style={{}}>
            <TabPanel
              item
              lg={12}
              md={10}
              sm={12}
              xs={11}
              value={value}
              index={0}
              style={{ width: "100%" }}
            >
              {" "}
              <Typography
                style={{
                  fontWeight: "bold",
                  textDecoration: "underline",
                  marginBottom: "20px",
                  // width: "30%",
                }}
              >
                {" "}
                DESCRIPTION : -
              </Typography>
              <Grid
                style={{
                  border: "1px solid black",
                  height: "100%",
                  marginBottom: "20px",
                  padding: "20px",
                  textAlign: "justify",
                }}
              >
                <Typography style={{}}>{rowData.description}</Typography>
              </Grid>
              <Grid>
                <Typography
                  style={{
                    fontWeight: "bold",
                    // marginBottom: "10px",
                    textDecoration: "underline",
                  }}
                >
                  YOUTUBE URL : -{" "}
                </Typography>
                <br />
                <Grid>
                  {" "}
                  {rowData.youtubeurl ? (
                    <a
                      href={rowData.youtubeurl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <iframe
                        src={`https://www.youtube.com/embed/${extractVideoID(
                          rowData.youtubeurl
                        )}`}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title="Embedded YouTube"
                        style={{ width: "300px", height: "300px" }} // Adjust width and height as needed
                      ></iframe>
                    </a>
                  ) : (
                    <p>No YouTube video available</p>
                  )}
                </Grid>
                <br />
                <Grid>
                  <Box
                    sx={{
                      width: "100%", // Adjust the width as needed
                      height: "100vh", // Adjust the height as needed
                      // Additional styling can be added here
                      display: showBox ? "block" : "none",
                    }}
                  >
                    <iframe
                      src={rowData.youtubeurl}
                      height="300"
                      scrolling="no"
                      title="vid2"
                      allowfullscreen=""
                    ></iframe>
                  </Box>
                </Grid>
                <Typography
                  style={{
                    fontWeight: "bold",
                    marginBottom: "10px",
                    // width: "30%",
                    textDecoration: "underline",
                  }}
                >
                  OTHER URL : - <br />
                  <a
                    href={formatUrl(rowData.otherurl)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <ul>
                      <li>{rowData.otherurl}</li>
                    </ul>
                  </a>
                </Typography>
              </Grid>
            </TabPanel>

            <TabPanel
              item
              lg={12}
              md={10}
              sm={12}
              xs={11}
              value={value}
              index={1}
              style={{ width: "100%" }}
            >
              <Grid container style={{ display: "flex", gap: "20px" }}>
                {" "}
                <Grid item>
                  {" "}
                  <Typography
                    style={{
                      fontWeight: "bold",
                      textDecoration: "underline",
                      marginBottom: "20px",
                    }}
                  >
                    VIDEOS : -
                  </Typography>
                  {/* //to fetch the multiple videos at once */}
                  {rowData.normalVideo && rowData.normalVideo.length > 0 ? (
                    <Grid container style={{ display: "flex", gap: "20px" }}>
                      {rowData.normalVideo.map((videoUrl, index) => (
                        <Grid item key={`video-item-${index}`}>
                          <video
                            height="300"
                            controls
                            controlsList="nodownload" // This tries to hide the download option in supporting browsers
                            title={`Video ${index}`}
                          >
                            <source
                              src={`${URL}/${videoUrl}`}
                              type="video/mp4"
                            />
                            Your browser does not support the video tag.
                          </video>
                        </Grid>
                      ))}
                    </Grid>
                  ) : (
                    <p>No videos available</p>
                  )}
                </Grid>{" "}
                {/* <Grid item>
                  <iframe
                    src={video0}
                    height="300"
                    scrolling="no"
                    title="vid2"
                    allowfullscreen=""
                  ></iframe>
                </Grid>
                <Grid item>
                  <iframe
                    src="//www.youtube.com/embed/Gbq1D0nn9VI"
                    height="300"
                    scrolling="no"
                    title="vid2"
                    allowfullscreen=""
                  ></iframe>
                </Grid>
                <Grid item>
                  <iframe
                    src={Rec_1}
                    height="300"
                    scrolling="no"
                    title="vid2"
                    allowfullscreen=""
                  ></iframe>
                </Grid> */}
              </Grid>
            </TabPanel>

            {/* <TabPanel value={value} index={2} style={{ width: "100%" }}>
              <Grid>
                <Typography
                  style={{
                    fontWeight: "bold",
                    textDecoration: "underline",
                    marginBottom: "20px",
                  }}
                >
                  DOCUMENTS : -
                </Typography>
                <ul style={{ display: "flex", gap: "20px" }}>
                  <li>
                    <Button
                      onClick={() => setShowBox1(!showBox1)} // Toggle visibility of the first box
                      style={{ textDecoration: "underline" }}
                    >
                      VIEW DOCUMENTS
                    </Button>
                  </li>
                </ul>
                <Box
                  sx={{
                    width: "100%",
                    height: "100vh",
                    display: showBox1 ? "block" : "none", // Use showBox1 to control visibility
                  }}
                >
                  <CloseIcon
                    onClick={() => setShowBox1(false)} // Close the first box
                    style={{
                      cursor: "pointer",
                      backgroundColor: "lightgrey",
                    }}
                  />
                  <embed
                    src={`${URL}/${rowData.docfile[0]}#toolbar=0`}
                    type="application/pdf"
                    width="100%"
                    height="100%"
                  />
                </Box>
              </Grid>
            </TabPanel> */}
            <TabPanel value={value} index={2} style={{ width: "100%" }}>
              <Grid>
                <Typography
                  style={{
                    fontWeight: "bold",
                    textDecoration: "underline",
                    marginBottom: "20px",
                  }}
                >
                  DOCUMENTS :
                </Typography>
                <ul style={{ display: "flex", gap: "20px", flexWrap: "wrap" }}>
                  {rowData.docfile.map((doc, index) => (
                    <li key={index}>
                      <Button
                        onClick={() => handleDocumentClick(doc)} // Handle document selection
                        style={{ textDecoration: "underline" }}
                      >
                        View Document {index + 1}
                      </Button>
                    </li>
                  ))}
                </ul>

                {showBox1 && selectedDoc && (
                  <Box
                    sx={{
                      width: "100%",
                      height: "100vh",
                      display: "block", // Show box when a document is selected
                    }}
                  >
                    <CloseIcon
                      onClick={() => setShowBox1(false)} // Close the document viewer
                      style={{
                        cursor: "pointer",
                        backgroundColor: "lightgrey",
                        // Consider adding positioning styles here for better visibility
                      }}
                    />
                    <embed
                      src={selectedDoc + "#toolbar=0"} // Display the selected document
                      type="application/pdf"
                      width="100%"
                      height="100%"
                      onContextMenu={(e) => e.preventDefault()}
                    />
                  </Box>
                )}
              </Grid>
            </TabPanel>

            {/* <TabPanel
              item
              lg={12}
              md={10}
              sm={12}
              xs={11}
              value={value}
              index={3}
              style={{ width: "100%" }}
            >
              <Typography
                style={{
                  fontWeight: "bold",
                  textDecoration: "underline",
                  marginBottom: "20px",
                }}
              >
                IMAGES : -
              </Typography>

              {rowData.imagefile && rowData.imagefile.length > 0 ? (
                <Grid container style={{ display: "flex", gap: "20px" }}>
                  {rowData.imagefile.map((imageUrl, index) => (
                    <Grid item key={`normal-img-${index}`}>
                      <img
                        src={`${URL}/${imageUrl}`}
                        style={{
                          width: "200px",
                          height: "200px",
                          cursor: "pointer",
                        }}
                        alt={`Image ${index}`}
                        onClick={() => handleOpen(`${URL}/${imageUrl}`)}
                      />
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <p>No images available</p>
              )}

              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    outline: "none",
                  }}
                >
                  <img
                    src={selectedImage}
                    alt="Zoomed"
                    style={{ maxWidth: "100vw", maxHeight: "100vh" }}
                  />
                </Box>
              </Modal>
            </TabPanel> */}

            <TabPanel
              item
              lg={12}
              md={10}
              sm={12}
              xs={11}
              value={value}
              index={4}
              style={{ width: "100%" }}
            >
              <Typography
                style={{
                  fontWeight: "bold",
                  textDecoration: "underline",
                  marginBottom: "20px",
                  // width: "30%",
                }}
              >
                {" "}
                ERROR DESCRIPTION : -
              </Typography>
              <Grid
                style={{
                  border: "1px solid black",
                  height: "100%",
                  marginBottom: "20px",
                  padding: "20px",
                  textAlign: "justify",
                }}
              >
                <Typography style={{}}>{rowData.errordescription}</Typography>
              </Grid>
              <Grid>
                <Typography
                  style={{
                    fontWeight: "bold",
                    textDecoration: "underline",
                    marginBottom: "20px",
                    // width: "30%",
                  }}
                >
                  {" "}
                  Expected SOLUTION : -
                </Typography>
                <Grid
                  style={{
                    border: "1px solid black",
                    height: "100%",
                    marginBottom: "20px",
                    padding: "20px",
                    textAlign: "justify",
                  }}
                >
                  <Typography style={{}}>{rowData.expectedsolution}</Typography>
                </Grid>
              </Grid>

              <Grid>
                <Grid>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      // marginBottom: "10px",
                      textDecoration: "underline",
                    }}
                  >
                    ERROR YOUTUBE URL : -{" "}
                  </Typography>
                  <br />
                  <Grid>
                    {" "}
                    {rowData.erroryoutubeurl ? (
                      <a
                        href={rowData.erroryoutubeurl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <iframe
                          src={`https://www.youtube.com/embed/${extractVideoID(
                            rowData.erroryoutubeurl
                          )}`}
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                          title="Embedded YouTube"
                          style={{ width: "300px", height: "300px" }} // Adjust width and height as needed
                        ></iframe>
                      </a>
                    ) : (
                      <p>No YouTube video available</p>
                    )}
                  </Grid>
                  <br />
                  <Grid>
                    <Box
                      sx={{
                        width: "100%", // Adjust the width as needed
                        height: "100vh", // Adjust the height as needed
                        // Additional styling can be added here
                        display: showBox ? "block" : "none",
                      }}
                    >
                      <iframe
                        src={rowData.youtubeurl}
                        height="300"
                        scrolling="no"
                        title="vid2"
                        allowfullscreen=""
                      ></iframe>
                    </Box>
                  </Grid>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      marginBottom: "10px",
                      // width: "30%",
                      textDecoration: "underline",
                    }}
                  >
                    OTHER URL : - <br />
                    <a
                      href={formatUrl(rowData.otherurl)}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <ul>
                        <li>{rowData.otherurl}</li>
                      </ul>
                    </a>
                  </Typography>
                </Grid>
                <Typography
                  style={{
                    fontWeight: "bold",
                    marginBottom: "10px",
                    // width: "30%",
                    textDecoration: "underline",
                  }}
                >
                  OTHER ERROR URL (REF): - <br />
                  <a
                    href={formatUrl(rowData.errorotherurl)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <ul>
                      <li>{rowData.errorotherurl}</li>
                    </ul>
                  </a>
                </Typography>
              </Grid>
            </TabPanel>

            <TabPanel
              item
              lg={12}
              md={10}
              sm={12}
              xs={11}
              value={value}
              index={5}
              style={{ width: "100%" }}
            >
              <Grid style={{ display: "flex", gap: "10px" }}>
                {" "}
                <Grid item>
                  {" "}
                  <Typography
                    style={{
                      fontWeight: "bold",
                      textDecoration: "underline",
                      marginBottom: "20px",
                    }}
                  >
                    ERROR VIDEOS : -
                  </Typography>
                  {rowData.errorVideo && rowData.errorVideo.length > 0 ? (
                    <Grid container style={{ display: "flex", gap: "20px" }}>
                      {rowData.errorVideo.map((videoUrl, index) => (
                        <Grid item key={`video-item-${index}`}>
                          <video
                            height="300"
                            controls
                            controlsList="nodownload" // This tries to hide the download option in supporting browsers
                            title={`Video ${index}`}
                          >
                            <source
                              src={`${URL}/${videoUrl}`}
                              type="video/mp4"
                            />
                            Your browser does not support the video tag.
                          </video>
                        </Grid>
                      ))}
                    </Grid>
                  ) : (
                    <p>No videos available</p>
                  )}
                </Grid>{" "}
                {/* <Grid item>
                  <iframe
                    src={video0}
                    height="300"
                    scrolling="no"
                    title="vid2"
                    allowfullscreen=""
                  ></iframe>
                </Grid> */}
              </Grid>
            </TabPanel>

            {/* <TabPanel value={value} index={6} style={{ width: "100%" }}>
              <Grid>
                <Typography
                  style={{
                    fontWeight: "bold",
                    textDecoration: "underline",
                    marginBottom: "20px",
                  }}
                >
                  DOCUMENTS : -
                </Typography>
                <ul style={{ display: "flex", gap: "20px" }}>
                  <li>
                    <Button
                      onClick={() => setShowBox2(!showBox2)} // Toggle visibility of the second box
                      style={{ textDecoration: "underline" }}
                    >
                      VIEW DOCUMENT
                    </Button>
                  </li>
                </ul>
                <Box
                  sx={{
                    width: "100%",
                    height: "100vh",
                    display: showBox2 ? "block" : "none", // Use showBox2 to control visibility
                  }}
                >
                  <CloseIcon
                    onClick={() => setShowBox2(false)} // Close the second box
                    style={{
                      cursor: "pointer",
                      backgroundColor: "lightgrey",
                    }}
                  />
                  <embed
                    src={`${URL}/${rowData.errdoc[0]}#toolbar=0`}
                    type="application/pdf"
                    width="100%"
                    height="100%"
                  />
                </Box>
              </Grid>
            </TabPanel> */}
            {/* <TabPanel
              item
              lg={12}
              md={10}
              sm={12}
              xs={11}
              value={value}
              index={7}
              style={{ width: "100%" }}
            >
              <Typography
                style={{
                  fontWeight: "bold",
                  textDecoration: "underline",
                  marginBottom: "20px",
                }}
              >
                UPLOADED ERROR IMAGES : -
              </Typography>
              <Typography>
                {" "}
                {rowData.errorimage && rowData.errorimage.length > 0 ? (
                  <Grid container style={{ display: "flex", gap: "20px" }}>
                    {rowData.errorimage.map((imageUrl, index) => (
                      <Grid item key={`normal-img-${index}`}>
                        <img
                          src={`${URL}/${imageUrl}`}
                          style={{
                            width: "200px",
                            height: "200px",
                            cursor: "pointer",
                          }}
                          alt={`Image ${index}`}
                          onClick={() => handleOpen(`${URL}/${imageUrl}`)}
                        />
                      </Grid>
                    ))}
                  </Grid>
                ) : (
                  <p>No images available</p>
                )}
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      outline: "none",
                    }}
                  >
                    <img
                      src={selectedImage}
                      alt="Zoomed"
                      style={{ maxWidth: "100vw", maxHeight: "100vh" }}
                    />
                  </Box>
                </Modal>
              </Typography>
            </TabPanel> */}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
