import React, { useEffect, useRef } from 'react';
import { getDocument } from 'pdfjs-dist/legacy/build/pdf';

const PDFViewer = ({ document }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    if (!document || !document.url) return; // Guard clause to ensure document and URL exist

    const fetchPDF = async () => {
      const loadingTask = getDocument(document.url);
      const pdf = await loadingTask.promise;

      // Assume you want to display the first page of the PDF
      const pageNumber = 1;
      const page = await pdf.getPage(pageNumber);

      const canvas = canvasRef.current;
      const context = canvas.getContext('2d');
      
      const viewport = page.getViewport({ scale: 1.0 }); // Adjust scale as needed
      canvas.height = viewport.height;
      canvas.width = viewport.width;
      
      const renderContext = {
        canvasContext: context,
        viewport: viewport,
      };
      await page.render(renderContext).promise;
    };

    fetchPDF().catch(console.error);
  }, [document]); // Depend on the `document` prop

  return <canvas ref={canvasRef}></canvas>;
};

export default PDFViewer;
