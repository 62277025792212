import ts from "../components/assets/Ts.png";
const hai = {
  backgroundColor: "#35A29F",
  padding: "5px",
  textAlign: "center",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const Footer = () => {
  return (
    <div style={{ bottom: "0px", position: "relative", width: "100%" }}>
      <p style={hai}>
        <img style={{ height: "30px" }} src={ts} alt="company logo" />{" "}
        <span>Copyright @ 2023</span>
      </p>
    </div>
  );
};

export default Footer;
