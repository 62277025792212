import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

function DataCollectionComponent() {
  const [dataCollection, setDataCollection] = useState([]);
  const [selectedData, setSelectedData] = useState(null); // To store and display selected data
  const navigate = useNavigate();

  useEffect(() => {
    const dataUrl = "https://thulasisolutions.in:3004/getData";
    fetch(dataUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((fetchedDataCollection) => {
        // console.log("Fetched data:", fetchedDataCollection); // Debug log
        setDataCollection(fetchedDataCollection);
      })
      .catch((error) => {
        console.error("Fetch error:", error);
        setDataCollection([]); // Set to empty array on error to prevent .map() error
      });
  }, []);
  console.log(dataCollection);

  const handleDataSelection = (item) => {
    setSelectedData(item); // Update the state with the selected item
    // console.log("Selected item data:", item); // For debugging
  };
  

  return (
    <div>
      {dataCollection.map((item) => (
        <button key={item.id} onClick={() => handleDataSelection(item)}>
          View Data for ID {item.id}
        </button>
      ))}
      {selectedData && (
        <div>
          <h3>Data for ID {selectedData.id}:</h3>
          Display the selected data. Adjust as needed based on your data
          structure
          <pre>{JSON.stringify(selectedData, null, 2)}</pre>
          <h3>Selected Item Overview</h3>
          <p>Name: {selectedData.name}</p>
          <p>Description: {selectedData.description}</p>
          <p>Error Url Link:{selectedData.radioButtonOption}</p>
        </div>
      )}
    </div>
  );
}

export default DataCollectionComponent;
