import React, { useState } from "react";
import { Grid, InputLabel, TextField, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styled } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import axios from "axios";
import RadioGroup from "@mui/material/RadioGroup";
import { Link } from "react-router-dom";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import useCheckbox from "./CheckedContext";
import dayjs from "dayjs";
import Stack from "@mui/material/Stack";
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useLocation } from "react-router-dom";
import CancelIcon from "@mui/icons-material/Cancel";
import { DemoItem } from "@mui/x-date-pickers/internals/demo";
import Tooltip from "@mui/material/Tooltip";

function Dataform() {
  const location = useLocation();
  const passedData = location.state?.data;
  const [isUpdateMode, setIsUpdateMode] = useState(!!passedData);
  // console.log(passedData);

  const InitialFormData = {
    name: "",
    date: "",
    course: "",
    title: "",
    description: "",
    youtubeurl: "",
    otherurl: "",
    status: "",
    errortitle: "",
    errordescription: "",
    expectedsolution: "",
    erroryoutubeurl: "",
    errorotherurl: "",
    docfile: [],
    normalvideo: [],
    imagefile: [],
    errordoc: [],
    errorimage: [],
    errorvideo: [],
  };
  const [formData, setFormData] = useState(passedData || InitialFormData);

  // const [formData, setFormData] = useState(InitialFormData);
  const handleInputChange = (event) => {
    let payload = { ...formData, [event.target.name]: event.target.value };
    setFormData(payload);
  };

  // const handleDateChange = (newDate) => {
  //   // Format the date to exclude the time part
  //   const formattedDate = newDate ? dayjs(newDate).format("YYYY-MM-DD") : "";
  //   setFormData({ ...formData, date: formattedDate });
  //   // console.log(formattedDate);
  // };
  console.log(formData.date);
  // Handle file upload hook
  const handleVideoUpload = (event) => {
    const files = Array.from(event.target.files);
    console.log(files);
    setFormData({
      ...formData,
      normalvideo: [...formData.normalvideo, ...files],
    });
    // setFormData({ ...formData, normalvideo: normalvideo });
  };
  // Handle document upload function

  const handleDocumentUpload = (event) => {
    const files = Array.from(event.target.files);
    // console.log(files);
    setFormData({ ...formData, docfile: [...formData.docfile, ...files] });
    // Reset the input value
    event.target.value = "";
  };
  // const handleImageUpload = (event) => {
  //   const files = Array.from(event.target.files);
  //   // console.log(files);
  //   setFormData({ ...formData, imagefile: [...formData.imagefile, ...files] });
  //   // Reset the input value
  //   event.target.value = "";
  // };

  const handleErrorVideoUpload = (event) => {
    const files = Array.from(event.target.files);
    console.log(files);
    setFormData({
      ...formData,
      errorvideo: [...formData.errorvideo, ...files],
    });
    // Reset the input value
    event.target.value = "";
    // setFormData({ ...formData, errorvideo: errorvideo });
  };

  const handleErrorDocUpload = (event) => {
    const files = Array.from(event.target.files);
    console.log(files);
    setFormData({ ...formData, errordoc: [...formData.errordoc, ...files] });
    // Reset the input value
    event.target.value = "";
    // setFormData({ ...formData, errordoc: errordoc });
  };
  // // const handlerrorImageUpload = (event) => {

  // // const handleImageUploaderror = (event) => {
  // //   const files = Array.from(event.target.files);
  // //   console.log(files);
  // //   setFormData({
  // //     ...formData,
  // //     errorimage: [...formData.errorimage, ...files],
  // //   });
  //   // Reset the input value
  //   event.target.value = "";
  // };
  const removeFile = (fileType, index) => {
    setFormData((prevState) => ({
      ...prevState,
      [fileType]: prevState[fileType].filter((_, i) => i !== index),
    }));
  };

  const Button1component = () => {
    return (
      <div>
        <Button fullWidth variant="contained" color="success" type="submit">
          {isUpdateMode ? "Update" : "Submit"}
        </Button>
      </div>
    );
  };
  // const InitialFormData = {};
  const [data, setData] = useState([]);
  // const [formData, setFormData] = useState(initialFormData);

  // Function to reset the form back to initial state
  // const resetForm = () => {
  //   setFormData(InitialFormData);
  // };

  const handleOnSubmit = async (event) => {
    event.preventDefault();
    const formDataToSend = new FormData();

    // Append other non-file fields
    Object.keys(formData).forEach((key) => {
      if (!Array.isArray(formData[key])) {
        formDataToSend.append(key, formData[key]);
      } else {
        // Append files for each type
        formData[key].forEach((file) => {
          formDataToSend.append(`${key}[]`, file);
        });
      }
    });

    // const isUpdateMode = !!passedData; // Convert to boolean
    // const url = isUpdateMode
    //   ? ``
    //   : "https://thulasisolutions.in:3004/createPost";
    // const method = isUpdateMode ? "PUT" : "POST";

    const isUpdateMode = !!passedData; // Convert to boolean
    const url = isUpdateMode
      ? `https://thulasisolutions.in:3004/updatePost`
      : "https://thulasisolutions.in:3004/createPost";
    const method = isUpdateMode ? "POST" : "POST";
    setData((prevData) => [...prevData, formData]);

    try {
      const response = await axios({
        method: method,
        url: url,
        data: formDataToSend,
        headers: { "Content-Type": "multipart/form-data" },
      });

      alert("YOUR DATA HAS BEEN SUBMITTED");
      // setIsUpdateMode(false);
      if (!isUpdateMode) {
        setFormData(InitialFormData);
      }
      // If using react-router's navigate to reset the component state
    } catch (error) {
      // Log the error if the request fails
      console.error("An error occurred during form submission:", error);
    }
    // console.log(formData);
  };
  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  // const [formData, setFormData] = useState(InitialFormData);
  const [isChecked1, , setIsChecked1] = useCheckbox(); // Destructure setIsChecked1 from useCheckbox
  const handleRadio = (event) => {
    const selectedValue = event.target.value;
    setFormData({ ...formData, radioButtonOption: selectedValue });
    setIsChecked1(selectedValue === "PENDING"); // Update isChecked1 based on radio selection
  };

  return (
    <div className="App" style={{ height: "100%" }}>
      {/* <Appbar /> */}
      <Grid
        container
        style={{
          backgroundColor: "#F8F9FA",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <form
          onSubmit={handleOnSubmit}
          style={{
            padding: "30px",
            backgroundColor: "#E6FFFD",
            paddingTop: "20px",
            paddingBottom: "20px",
            marginTop: "20px",
            marginBottom: "10px",
            width: "40%",
          }}
        >
          <Grid
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              // alignItems:"center",
              gap: "0px",
            }}
          >
            <Grid style={{ padding: "10px" }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Name</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="name"
                  value={formData.name}
                  name="name"
                  onChange={handleInputChange}
                  style={{ width: "300px" }}
                >
                  <MenuItem value="SUDHEER">Sudheer</MenuItem>
                  <MenuItem value="NAGA LAKSHMI">NagaLakshmi</MenuItem>
                  <MenuItem value="SIVA SINDHU">Siva Sindhu</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {/* <Grid style={{ padding: "10px" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    name="date"
                    label="Choose Date"
                    value={formData.date}
                    onChange={handleDateChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        helperText="Please fill this field"
                      />
                    )}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Grid>{" "} */}

            <Grid style={{ padding: "10px" }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Course</InputLabel>
                <Select
                  label="course"
                  value={formData.course}
                  name="course"
                  onChange={handleInputChange}
                  style={{ width: "200px" }}
                >
                  <MenuItem value="ABAP">ABAP</MenuItem>
                  <MenuItem value="TM">TM</MenuItem>
                  <MenuItem value="EWM">EWM</MenuItem>
                  {/* <MenuItem value="FIORI">FIORI</MenuItem> */}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid style={{ padding: "10px" }}>
            <TextField
              fullWidth
              label="Title"
              type="text"
              name="title"
              value={formData.title}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid style={{ padding: "10px" }}>
            <TextField
              fullWidth
              id="outlined-multiline-static fullWidth"
              label="description"
              name="description"
              multiline
              rows={4}
              value={formData.description}
              onChange={handleInputChange}
              // defaultValue="Enter Text"
            />
          </Grid>
          {/* File upload buttons grid */}
          <Grid
            // container
            style={{
              display: "flex",
              // gap: "30px",
              justifyContent: "center",
              alignItems: "center",
              paddingTop: "10px",
              paddingBottom: "10px",
              gap: "40px",
            }}
          >
            <Grid
              // item
              // xs={5}
              style={{
                padding: "10px",
                display: "flex",
                width: "220px",
                flexDirection: "column",
                fontWeight: "bold",
                backgroundColor: "#508D69",
                borderRadius: "10px",
              }}
            >
              <Typography
                style={{
                  width: "100%",
                  color: "white",
                  fontWeight: "bold",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                  paddingBottom: "5px",
                  backgroundColor: "#508D69",
                }}
              >
                <CloudUploadIcon /> Upload Doc
              </Typography>
              <Button
                component="label"
                variant="contained"
                style={{ backgroundColor: "#89B9AD" }}
              >
                <input
                  type="file"
                  name="docfile"
                  multiple
                  style={{ cursor: "pointer", display: "none" }}
                  onChange={handleDocumentUpload}
                />
                Choose Files
              </Button>{" "}
              {formData.docfile?.map((file, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "10px",
                    fontSize: "0.9rem",
                  }}
                >
                  {file.name}
                  <CancelIcon
                    onClick={() => removeFile("docfile", index)}
                    style={{
                      cursor: "pointer",
                      color: "brown",
                      height: "1.3rem",
                    }}
                  />
                </div>
              ))}
            </Grid>
            <Grid
              // item
              // xs={5}
              style={{
                padding: "10px",
                display: "flex",
                width: "220px",
                flexDirection: "column",
                fontWeight: "bold",
                backgroundColor: "#8ADAB2",
                borderRadius: "10px",
              }}
            >
              {/* <TextField type="file" /> */}
              <Typography
                style={{
                  width: "100%",
                  color: "black",
                  fontWeight: "bold",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                  paddingBottom: "5px",
                  backgroundColor: "#8ADAB2",
                }}
              >
                <CloudUploadIcon /> Upload Video
              </Typography>
              <Button
                component="label"
                variant="contained"
                style={{ backgroundColor: "lightblue" }}
              >
                <input
                  type="file"
                  name="normalvideo"
                  accept="video/*"
                  multiple
                  style={{ cursor: "pointer", display: "none" }}
                  onChange={handleVideoUpload}
                />{" "}
                Choose Files
              </Button>{" "}
              {formData.normalvideo?.map((file, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "10px",
                    fontSize: "0.9rem",
                  }}
                >
                  {file.name}
                  <CancelIcon
                    onClick={() => removeFile("normalvideo", index)}
                    style={{
                      cursor: "pointer",
                      color: "brown",
                      height: "1.3rem",
                    }}
                  />
                </div>
              ))}
            </Grid>

            {/* <Grid
              style={{
                padding: "10px",
                display: "flex",
                width: "220px",
                flexDirection: "column",
                fontWeight: "bold",
                backgroundColor: "#508D69",
                borderRadius: "10px",
              }}
            >
              <Typography
                style={{
                  width: "100%",
                  color: "white",
                  fontWeight: "bold",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                  paddingBottom: "5px",
                  backgroundColor: "#508D69",
                }}
              >
                <CloudUploadIcon /> Upload Images
              </Typography>
              <Button
                component="label"
                variant="contained"
                style={{ backgroundColor: "#89B9AD" }}
              >
                <input
                  type="file"
                  name="imagefile"
                  accept="image/*"
                  multiple
                  style={{ cursor: "pointer", display: "none" }}
                  onChange={handleImageUpload}
                />
                Choose Images
              </Button>{" "}
              {formData.imagefile?.map((file, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "10px",
                    fontSize: "0.9rem",
                  }}
                >
                  {file.name}
                  <CancelIcon
                    onClick={() => removeFile("imagefile", index)}
                    style={{
                      cursor: "pointer",
                      color: "brown",
                      height: "1.3rem",
                    }}
                  />
                </div>
              ))}
            </Grid> */}
          </Grid>
          {/* URL Link upload Grid */}
          <Grid
            style={{
              padding: "10px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <TextField
              fullWidth
              label="Youtube URLs"
              id="fullWidth"
              name="youtubeurl"
              value={formData.youtubeurl}
              onChange={handleInputChange}
              style={{ width: "48%" }}
            />
            <TextField
              fullWidth
              label="Other URLs"
              id="fullWidth"
              name="otherurl"
              value={formData.otherurl}
              onChange={handleInputChange}
              style={{ width: "48%" }}
            />
          </Grid>

          <Grid style={{ display: "flex" }}>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="female"
              name="radio-buttons-group"
              style={{ marginLeft: "20px" }}
            >
              <Grid style={{ display: "flex", gap: "10px" }}>
                <input
                  type="radio"
                  name=""
                  value="SUCCESS"
                  checked={formData.radioButtonOption === "SUCCESS"}
                  onChange={handleRadio}
                  // onClick={handleCheckboxChange1===false}
                />{" "}
                SUCCESS
                <input
                  type="radio"
                  value="PENDING"
                  checked={formData.radioButtonOption === "PENDING"}
                  onChange={handleRadio}
                  // onClick={handleCheckboxChange1}
                />{" "}
                PENDING
              </Grid>
            </RadioGroup>
          </Grid>

          {/* Toggle visibility based on isChecked state */}
          {isChecked1 && (
            <Grid>
              <Grid style={{ padding: "10px" }}>
                <TextField
                  fullWidth
                  label="Error Title"
                  value={formData.errortitle}
                  id="fullWidth"
                  name="errortitle"
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid style={{ padding: "10px", display: "flex", gap: "10px" }}>
                <TextField
                  fullWidth
                  id="outlined-multiline-static fullWidth"
                  label="Error Description"
                  name="errordescription"
                  value={formData.errordescription}
                  multiline
                  rows={3}
                  onChange={handleInputChange}
                />
                <TextField
                  fullWidth
                  id="outlined-multiline-static fullWidth"
                  label="Expected Solution"
                  name="expectedsolution"
                  value={formData.expectedsolution}
                  multiline
                  rows={3}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid
                // container
                style={{
                  display: "flex",
                  justifyContent: "center",
                  // gap: "30px",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  gap: "40px",
                }}
              >
                <Grid
                  // item
                  // xs={5}
                  style={{
                    padding: "10px",
                    display: "flex",
                    width: "220px",
                    flexDirection: "column",
                    fontWeight: "bold",
                    backgroundColor: "#508D69",
                    borderRadius: "10px",
                  }}
                >
                  <Typography
                    style={{
                      width: "100%",
                      color: "white",
                      fontWeight: "bold",
                      display: "flex",
                      justifyContent: "center",
                      textAlign: "center",
                      paddingBottom: "5px",
                      gap: "10px",
                      backgroundColor: "#508D69",
                    }}
                  >
                    <CloudUploadIcon />
                    Erro Upload Doc
                  </Typography>
                  <Button
                    component="label"
                    variant="contained"
                    style={{ backgroundColor: "#89B9AD" }}
                  >
                    <input
                      type="file"
                      name="errordoc"
                      multiple
                      style={{ cursor: "pointer", display: "none" }}
                      onChange={handleErrorDocUpload}
                    />
                    Choose Files
                  </Button>{" "}
                  {formData.errordoc?.map((file, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "10px",
                        fontSize: "0.9rem",
                      }}
                    >
                      {file.name}
                      <CancelIcon
                        onClick={() => removeFile("errordoc", index)}
                        style={{
                          cursor: "pointer",
                          color: "brown",
                          height: "1.3rem",
                        }}
                      />
                    </div>
                  ))}
                </Grid>
                <Grid
                  // item
                  // xs={5}
                  style={{
                    padding: "10px",
                    display: "flex",
                    width: "220px",
                    flexDirection: "column",
                    fontWeight: "bold",
                    backgroundColor: "#8ADAB2",
                    borderRadius: "10px",
                  }}
                >
                  <Typography
                    style={{
                      width: "100%",
                      color: "black",
                      fontWeight: "bold",
                      backgroundColor: "#8ADAB2",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",

                      gap: "10px",
                      paddingBottom: "5px",
                    }}
                  >
                    <CloudUploadIcon />
                    Error Upload Video
                  </Typography>
                  <Button
                    component="label"
                    variant="contained"
                    style={{ backgroundColor: "lightblue" }}
                  >
                    <input
                      type="file"
                      name="errorvideo"
                      accept="video/*"
                      multiple
                      style={{ cursor: "pointer", display: "none" }}
                      onChange={handleErrorVideoUpload}
                    />
                    Choose Videos
                  </Button>{" "}
                  {formData.errorvideo?.map((file, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "10px",
                        fontSize: "0.9rem",
                      }}
                    >
                      {file.name}
                      <CancelIcon
                        onClick={() => removeFile("errorvideo", index)}
                        style={{
                          cursor: "pointer",
                          color: "brown",
                          height: "1.3rem",
                        }}
                      />
                    </div>
                  ))}
                </Grid>
                {/* <Grid
                  style={{
                    padding: "10px",
                    display: "flex",
                    width: "220px",
                    flexDirection: "column",
                    fontWeight: "bold",
                    backgroundColor: "#508D69",
                    borderRadius: "10px",
                  }}
                >
                  <Typography
                    style={{
                      width: "100%",
                      color: "white",
                      fontWeight: "bold",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "10px",
                      paddingBottom: "5px",
                      backgroundColor: "#508D69",
                    }}
                  >
                    <CloudUploadIcon /> Upload Images
                  </Typography>
                  <Button
                    component="label"
                    variant="contained"
                    style={{ backgroundColor: "#89B9AD" }}
                  >
                    <input
                      type="file"
                      multiple
                      accept="image/*"
                      name="errorimage"
                      onChange={handleImageUploaderror}
                      style={{ display: "none" }}
                    />
                    Choose Images
                  </Button>{" "}
                  {formData.errorimage?.map((file, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "10px",
                        fontSize: "0.9rem",
                      }}
                    >
                      {file.name}
                      <CancelIcon
                        onClick={() => removeFile("errorimage", index)}
                        style={{
                          cursor: "pointer",
                          color: "brown",
                          height: "1.3rem",
                        }}
                      />
                    </div>
                  ))}
                </Grid> */}
              </Grid>
              {/* URL Link upload Grid */}

              <Grid
                style={{
                  padding: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <TextField
                  fullWidth
                  label="Youtube URLs (ERROR-REF)"
                  id="fullWidth"
                  name="erroryoutubeurl"
                  value={formData.erroryoutubeurl}
                  onChange={handleInputChange}
                  style={{ width: "48%" }}
                />
                <TextField
                  fullWidth
                  label="Other URLs (ERROR-REF)"
                  id="fullWidth"
                  name="errorotherurl"
                  value={formData.errorotherurl}
                  onChange={handleInputChange}
                  style={{ width: "48%" }}
                />
              </Grid>
            </Grid>
          )}
          {/* submit Button */}
          <Grid style={{ padding: "10px" }}>
            <Button1component />
          </Grid>
          <Grid
            style={{
              padding: "10px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Link to="/abapptable" style={{ width: "45%" }}>
              <Button fullWidth variant="contained" type="submit">
                VIEW CONTENT
              </Button>
            </Link>
            <Link to="/loginform" style={{ width: "45%" }}>
              <Button fullWidth variant="contained">
                Logout
              </Button>
            </Link>
          </Grid>
        </form>
      </Grid>
      {/* <Footer /> */}
      {/* <div>{formData.title}</div> */}
    </div>
  );
}

export default Dataform;
