import React, { useState } from "react";
import "./App.css";
import Dataform from "./components/dataform";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import RootLayout from "./components/router";
import FullWidthTabs4 from "./components/abapptable";
import VerticalTabs1 from "./components/successview";
import BasicTextFields from "./components/loginform";
import Login from "./components/loginform";
import PSPDFKitComponent from "./components/word_pdf";
// import Pdf_viewer from "./components/Pdf_viewer";
import DataCollectionComponent from "./components/New folder/testffile";
import PDFViewer from "./components/Pdf_viewer";
// import DataSelector from "./components/button";




const common = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    children: [
      { path: "/", element: <Login /> },
      { path: "/successview", element: <VerticalTabs1 /> },
      { path: "/abapptable", element: <FullWidthTabs4 /> },
      { path: "/loginform", element: <BasicTextFields /> },
      { path: "/dataform", element: <Dataform /> },
      { path: "/word_pdf", element: <PSPDFKitComponent /> },
      { path: "/Pdf_viewer", element: <PDFViewer /> },
      { path: "/testfile", element: <DataCollectionComponent /> },
    ],
  },
]);

function App() {
  return (
    <div className="App">
      <RouterProvider router={common} />
    </div>
  );
}
export default App;
